import React from "react"
import { Link } from "gatsby"

import Layout from "../components/de/layout"
//import Image from "../components/image"
import SEO from "../components/de/seo"

import MainImg from "./../images/main_bg.jpg"

import shape1 from "./../images/dec/1.png"
import shape2 from "./../images/dec/2.png"
import shape3 from "./../images/dec/3.png"
import shape4 from "./../images/dec/4.png"
import shape5 from "./../images/dec/5.png"
import shape6 from "./../images/dec/6.png"
import shape7 from "./../images/dec/7.png"
import shape8 from "./../images/dec/8.png"

import product1 from "./../images/moonamar-kaltgepresstes-bio-schwarzkummelol-250-ml.png"
import product2 from "./../images/moonamar-schwarzkummelol-in-halal-kapseln-250-stuck.png"
import product3 from "./../images/moonamar-shampoo-mit-arganol-schwarzkummelol.png"
import product4 from "./../images/moonamar-hautbalsam-mit-argan-schwarzkummel-jojoba.png"
import product5 from "./../images/moonamar-sheabutterseife-mit-schwarzkummelol-vit-e.png"
import product6 from "./../images/moonamar-bonbons-mit-schwarzkummel-kurkuma-minze.png"

const Produkte = () => (
  <Layout>
    <SEO
      title="MOONAMAR - NAHRUNGSERGÄNZUNGSMITTEL UND KOSMETIK"
      description="MOONAMAR-Produkte entstehen als Ergebnis einer ergebenen Arbeit an natürlichen Rezepturen mit wohltuenden Wirkungen."
    />

    <section
      id="_heroPage"
      className="hero is-large _o-nama"
      style={{ backgroundImage: `url(${MainImg})` }}
    >
      <div id="bgHero">
        <span className="shape1">
          <img src={shape1} alt="Shape 1" />
        </span>
        <span className="shape2">
          <img src={shape2} alt="Shape 2" />
        </span>
        <span className="shape3">
          <img src={shape3} alt="Shape 3" />
        </span>
        <span className="shape4">
          <img src={shape4} alt="Shape 4" />
        </span>
        <span className="shape5">
          <img src={shape5} alt="Shape 5" />
        </span>
        <span className="shape6">
          <img src={shape6} alt="Shape 6" />
        </span>
        <span className="shape7">
          <img src={shape7} alt="Shape 7" />
        </span>
        <span className="shape8">
          <img src={shape2} alt="Shape 8" />
        </span>
      </div>
      <div className="hero-body">
        <div className="container">
          <div className="title">PRODUKTE</div>
        </div>
      </div>
    </section>

    <span className="_line" />

    <section id="_pageContent_other" className="section">
      <span className="_dec2">
        <img src={shape8} alt="Dec 2" />
      </span>
      <div className="container">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <div id="produkte_block" className="columns is-hidden-touch">
              <div className="column is-2 is-narrow">
                <Link to="/produkt/bio-schwarzkummelol">
                  <figure className="image">
                    <img
                      src={product1}
                      alt="KALTGEPRESSTES BIO-SCHWARZKÜMMELÖL 250 ML"
                      title="KALTGEPRESSTES BIO-SCHWARZKÜMMELÖL 250 ML"
                    />
                  </figure>
                </Link>
              </div>
              <div className="column is-2 is-narrow">
                <Link to="/produkt/schwarzkummelol-in-kapseln">
                  <figure className="image">
                    <img
                      src={product2}
                      alt="SCHWARZKÜMMELÖL IN HALAL-KAPSELN - 250 Stück"
                      title="SCHWARZKÜMMELÖL IN HALAL-KAPSELN - 250 Stück"
                    />
                  </figure>
                </Link>
              </div>
              <div className="column is-2 is-narrow">
                <Link to="/produkt/schampoo-mit-argan-und-schwarzkummel">
                  <figure className="image">
                    <img
                      src={product3}
                      alt="SHAMPOO MIT ARGANÖL & SCHWARZKÜMMELÖL"
                      title="SHAMPOO MIT ARGANÖL & SCHWARZKÜMMELÖL"
                    />
                  </figure>
                </Link>
              </div>
              <div className="column is-2 is-narrow">
                <Link to="/produkt/hautbalsam-mit-schwarzkummel-argan-jojoba-avocado-sheabutter">
                  <figure className="image">
                    <img
                      src={product4}
                      alt="HAUTBALSAM MIT ARGAN, SCHWARZKÜMMEL, JOJOBA"
                      title="HAUTBALSAM MIT ARGAN, SCHWARZKÜMMEL, JOJOBA"
                    />
                  </figure>
                </Link>
              </div>
              <div className="column is-2 is-narrow">
                <Link to="/produkt/sheabutterseife-mit-schwarzkummelol">
                  <figure className="image">
                    <img
                      src={product5}
                      alt="SHEABUTTERSEIFE MIT SCHWARZKÜMMELÖL & VIT. E"
                      title="SHEABUTTERSEIFE MIT SCHWARZKÜMMELÖL & VIT. E"
                    />
                  </figure>
                </Link>
              </div>
              <div className="column is-2 is-narrow">
                <Link to="/produkt/bonbons-mit-schwarzkummel-kurkuma-minze-und-zitrone">
                  <figure className="image">
                    <img
                      src={product6}
                      alt="BONBONS MIT SCHWARZKÜMMEL, KURKUMA & MINZE"
                      title="BONBONS MIT SCHWARZKÜMMEL, KURKUMA & MINZE"
                    />
                  </figure>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div id="produkte_list" className="columns">
          <div className="column is-8 is-offset-2">
            <div className="columns __prod">
              <div className="column is-3">
                <nav className="_sidebar">
                  <Link to="/produkte/#1">BIO-Schwarzkümmelöl</Link>
                  <Link to="/produkte/#2">Schwarzkümmelöl in Kapseln</Link>
                  <Link to="/produkte/#3">
                    Schampoo mit Argan und Schwarzkümmel
                  </Link>
                  <Link to="/produkte/#4">
                    Hautbalsam mit Schwarzkümmel, Argan, Jojoba, Avocado &
                    Sheabutter
                  </Link>
                  <Link to="/produkte/#5">
                    Sheabutterseife mit Schwarzkümmelöl
                  </Link>
                  <Link to="/produkte/#6">
                    Bonbons mit Schwarzkümmel, Kurkuma, Minze und Zitrone
                  </Link>
                </nav>
              </div>
              <div className="column is-9">
                <h3 className="title">MOONAMAR EXKLUSIVE PRODUKTAUSWAHL</h3>
                <p className="content">
                  MOONAMAR-Produkte entstehen als Ergebnis einer Ergebenheit der
                  Natur und sorgfältiger Arbeit an Rezepturen mit wohltuenden
                  Wirkungen
                </p>
                <div id="1" className="columns __prod">
                  <div className="column is-4">
                    <figure className="image is-desktop">
                      <img
                        src={product1}
                        alt="KALTGEPRESSTES BIO-SCHWARZKÜMMELÖL 250 ML"
                        title="KALTGEPRESSTES BIO-SCHWARZKÜMMELÖL 250 ML"
                      />
                    </figure>
                  </div>
                  <div className="column">
                    <p className="title is-4">BIO-Schwarzkümmelöl</p>
                    <figure className="image is-mobile">
                      <img
                        src={product1}
                        alt="KALTGEPRESSTES BIO-SCHWARZKÜMMELÖL 250 ML"
                        title="KALTGEPRESSTES BIO-SCHWARZKÜMMELÖL 250 ML"
                      />
                    </figure>
                    <p className="content">
                      MOONAMAR Schwarzkümmelöl wird durch Kaltpressen gewonnen
                      und keiner weiteren Verarbeitung ausgesetzt, um die im
                      Saatgut enthaltenen wohltuenden Substanzen zu schonen.
                    </p>
                    <Link
                      to="/produkt/bio-schwarzkummelol"
                      className="button is-text is-small btn2"
                    >
                      MEHR ERFAHREN (250ml)
                    </Link>
                    &nbsp; &nbsp;
                    <Link
                      to="/produkt/bio-schwarzkummelol-1"
                      className="button is-text is-small btn2"
                    >
                      MEHR ERFAHREN (100ml)
                    </Link>
                  </div>
                </div>
                <div id="2" className="columns __prod">
                  <div className="column">
                    <p className="title is-4">Schwarzkümmelöl in Kapseln</p>
                    <figure className="image is-mobile">
                      <img
                        src={product2}
                        alt="SCHWARZKÜMMELÖL IN HALAL-KAPSELN - 250 Stück"
                        title="SCHWARZKÜMMELÖL IN HALAL-KAPSELN - 250 Stück"
                      />
                    </figure>
                    <p className="content">
                      MOONAMAR Schwarzkümmelöl-Kapseln bestehen aus einer
                      halal-zertifizierten Gelatin-Hülle und aus kaltgepresstem,
                      jungfräulichem Öl, gewonnen aus egyptischen
                      Schwarzkümmelsamen. Vefügbar in Verpackungen von 90 und
                      250 Kapseln.
                    </p>
                    <Link
                      to="/produkt/schwarzkummelol-in-kapseln"
                      className="button is-text is-small btn2"
                    >
                      MEHR ERFAHREN (90 Stück)
                    </Link>
                    &nbsp; &nbsp;
                    <Link
                      to="/produkt/schwarzkummelol-in-kapseln-1"
                      className="button is-text is-small btn2"
                    >
                      MEHR ERFAHREN (250 Stück)
                    </Link>
                  </div>
                  <div className="column is-4">
                    <figure className="image is-desktop">
                      <img
                        src={product2}
                        alt="SCHWARZKÜMMELÖL IN HALAL-KAPSELN - 250 Stück"
                        title="SCHWARZKÜMMELÖL IN HALAL-KAPSELN - 250 Stück"
                      />
                    </figure>
                  </div>
                </div>
                <div id="3" className="columns __prod">
                  <div className="column is-4">
                    <figure className="image is-desktop">
                      <img
                        src={product3}
                        alt="SHAMPOO MIT ARGANÖL & SCHWARZKÜMMELÖL"
                        title="SHAMPOO MIT ARGANÖL & SCHWARZKÜMMELÖL"
                      />
                    </figure>
                  </div>
                  <div className="column">
                    <p className="title is-4">
                      Schampoo mit Argan und Schwarzkümmel
                    </p>
                    <figure className="image is-mobile">
                      <img
                        src={product3}
                        alt="SHAMPOO MIT ARGANÖL & SCHWARZKÜMMELÖL"
                        title="SHAMPOO MIT ARGANÖL & SCHWARZKÜMMELÖL"
                      />
                    </figure>
                    <p className="content">
                      MOONAMAR Shampoo mit Argan- und Schwarzkümmelöl eignet
                      sich als milde Haarreinigung für eine besonders
                      empfindliche Haut.
                    </p>
                    <Link
                      to="/produkt/schampoo-mit-argan-und-schwarzkummel"
                      className="button is-text is-small btn2"
                    >
                      MEHR ERFAHREN
                    </Link>
                  </div>
                </div>
                <div id="4" className="columns __prod">
                  <div className="column">
                    <p className="title is-4">
                      Hautbalsam mit Schwarzkümmel, Argan, Jojoba, Avocado &
                      Sheabutter
                    </p>
                    <figure className="image is-mobile">
                      <img
                        src={product4}
                        alt="HAUTBALSAM MIT ARGAN, SCHWARZKÜMMEL, JOJOBA"
                        title="HAUTBALSAM MIT ARGAN, SCHWARZKÜMMEL, JOJOBA"
                      />
                    </figure>
                    <p className="content">
                      MOONAMAR Balsam eignet sich als sehr reichhaltige Pflege
                      für trockene und empfindliche Haut.
                    </p>
                    <Link
                      to="/produkt/hautbalsam-mit-schwarzkummel-argan-jojoba-avocado-sheabutter"
                      className="button is-text is-small btn2"
                    >
                      MEHR ERFAHREN
                    </Link>
                  </div>
                  <div className="column is-4">
                    <figure className="image is-desktop">
                      <img
                        src={product4}
                        alt="HAUTBALSAM MIT ARGAN, SCHWARZKÜMMEL, JOJOBA"
                        title="HAUTBALSAM MIT ARGAN, SCHWARZKÜMMEL, JOJOBA"
                      />
                    </figure>
                  </div>
                </div>
                <div id="5" className="columns __prod">
                  <div className="column is-4">
                    <figure className="image is-desktop">
                      <img
                        src={product5}
                        alt="SHEABUTTERSEIFE MIT SCHWARZKÜMMELÖL & VIT. E"
                        title="SHEABUTTERSEIFE MIT SCHWARZKÜMMELÖL & VIT. E"
                      />
                    </figure>
                  </div>
                  <div className="column">
                    <p className="title is-4">
                      Sheabutterseife mit Schwarzkümmelöl
                    </p>
                    <figure className="image is-mobile">
                      <img
                        src={product5}
                        alt="SHEABUTTERSEIFE MIT SCHWARZKÜMMELÖL & VIT. E"
                        title="SHEABUTTERSEIFE MIT SCHWARZKÜMMELÖL & VIT. E"
                      />
                    </figure>
                    <p className="content">
                      MOONAMAR Sheabutterseife- für milde und hautfreundliche
                      Reinigung. Besonders geeignet für die empfindliche und zu
                      Allergien neigende Haut.
                    </p>
                    <Link
                      to="/produkt/sheabutterseife-mit-schwarzkummelol"
                      className="button is-text is-small btn2"
                    >
                      MEHR ERFAHREN
                    </Link>
                  </div>
                </div>
                <div id="6" className="columns __prod">
                  <div className="column">
                    <p className="title is-4">
                      Bonbons mit Schwarzkümmel, Kurkuma, Minze und Zitrone
                    </p>
                    <figure className="image is-mobile">
                      <img
                        src={product6}
                        alt="BONBONS MIT SCHWARZKÜMMEL, KURKUMA & MINZE"
                        title="BONBONS MIT SCHWARZKÜMMEL, KURKUMA & MINZE"
                      />
                    </figure>
                    <p className="content">
                      Wohlschmeckend und wohltuend für Hals und Atemwege.
                    </p>
                    <Link
                      to="/produkt/bonbons-mit-schwarzkummel-kurkuma-minze-und-zitrone"
                      className="button is-text is-small btn2"
                    >
                      MEHR ERFAHREN
                    </Link>
                  </div>
                  <div className="column is-4">
                    <figure className="image is-desktop">
                      <img
                        src={product6}
                        alt="BONBONS MIT SCHWARZKÜMMEL, KURKUMA & MINZE"
                        title="BONBONS MIT SCHWARZKÜMMEL, KURKUMA & MINZE"
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span className="_dec3">
        <img src={shape6} alt="Dec 3" />
      </span>
    </section>
  </Layout>
)

export default Produkte
